.packages-card{
    &::before{
        content: "";
    z-index: 1;
    // border-radius: 16px;
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 24%, rgb(0, 0, 0) 112%);
    mix-blend-mode: multiply;
    pointer-events: none;
    }
}
.color-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    pointer-events: none;
}

.custome-navbar{
    .nav-item{
        .nav-link{
            border-radius: 6px;
            padding: 12px 12px;
            &.active{
                background-color: $orange-200;
                color: $primary;
            }
        }
    }
}
.table-design {
    thead{
        tr{
            th{
                font-size: 0.875rem;
                color: $dark;
            }
        }
    }
    tbody{
        tr{
            td{
                color: $gray;
                font-size: 0.875rem;
            }
        }
    }
}