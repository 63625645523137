.tv-header {
    position: sticky !important;
    top: 0 !important;
    z-index: 1020 !important;

    .navbar-collapse {
        &::before {
            content: none;
        }

        .navbar-nav {
            .nav-link {
                span {
                    position: relative;
                    display: block;
                    width: max-content;

                    &::after {
                        height: 2px;
                        width: 0;
                        background: #bf5337;
                        content: "";
                        position: absolute;
                        right: 0;
                        bottom: -2px;
                        transition: 0.7s all;
                    }
                }

                &:hover {
                    color: #192432;

                    span {
                        &::after {
                            width: 100%;
                            right: auto;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}