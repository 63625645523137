//import the functions & variables
@import "@webpixels/css/core/functions";
@import "@webpixels/css/core/variables";
// Theam chnages
$font-family-base: "Montserrat", sans-serif;
$display-font-family: $font-family-base;
$font-size-base: 1rem;
$font-weight-base: 400;

// Card
$card-border-radius: 8px;

// Button
$btn-padding-y: 8px;
$btn-padding-x: 16px;
$btn-line-height: 1.5;
$btn-border-radius: 4px;
// $btn-font-weight: 600;
$btn-box-shadow: none;

$btn-padding-y-lg: 12px;
$btn-padding-x-lg: 24px;
$btn-font-size-lg: 16px;
$btn-border-radius-lg: 4px;


// Font Size
$font-size-xs: 0.75rem; // 12px
$font-size-sm: 0.875rem; // 14px
$font-size-base: 1rem; // 16px
$font-size-md: 1.125rem; // 18px
$font-size-lg: 1.25rem; // 20px
$font-size-xl: 1.5rem; // 24px
$font-size-2xl: 2rem; // 32px



// navbar
$navbar-vertical-open-width: 272px;

//avtar
$avatar-width-2xl: 200px;
$avatar-width-xl: 64px;
$avatar-width-lg: 56px;
$avatar-width: 52px;
$avatar-width-sm: 48px;
$avatar-width-xs: 32px;

// Dropdown
$dropdown-padding-x: 0px;
$dropdown-padding-y: 0px;
$dropdown-border-radius: 4px;
$dropdown-box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);

// Card
$card-border-width: 0px;
// $card-border-radius: 8px;
$card-box-shadow: none;

// Badge
$badge-padding-x: 16px;
$badge-padding-y: 6px;

// Form input group
$input-group-addon-bg: transparent;
$input-box-shadow: none;
$input-color: $dark;
$input-placeholder-color: $gray;

// Form Inputs
$form-check-input-width: 1.25rem;
$form-check-padding-start: $form-check-input-width * 1.5;

// Form Validations
$form-feedback-icon-valid-color: none;
$form-feedback-icon-valid: none;
$form-feedback-icon-invalid-color: none;
$form-feedback-icon-invalid: none;


// Alerts
$alert-padding-x: 1.5rem;
$alert-padding-y: 1.5rem;
$alert-margin-bottom: 1.5rem;

$spacer: 1rem;
$spacer-values: (
  0: 0,
  // 0px
  1: $spacer * 0.25,
  // 4px
  2: $spacer * 0.5,
  // 8px
  3: $spacer * 0.75,
  // 12px
  4: $spacer * 1,
  // 16px
  5: $spacer * 1.25,
  // 20px
  6: $spacer * 1.5,
  // 24px
  7: $spacer * 1.75,
  // 28px
  8: $spacer * 2,
  // 32px
  9: $spacer * 2.25,
  // 36px
  10: $spacer * 2.5,
  // 40px
  12: $spacer * 3,
  // 48px
  14: $spacer * 3.5,
  // 56px
  16: $spacer * 4,
  // 64px
  18: $spacer * 4.5,
  // 72px
  20: $spacer * 5,
  // 80px
  24: $spacer * 6,
  // 96px
  30: $spacer * 7.5,
  // 120px
  32: $spacer * 8,
  // 128px
  40: $spacer * 10,
  // 160px
  48: $spacer * 12,
  // 192px
  56: $spacer * 14,
  // 224px
  64: $spacer * 16,
  // 256px
  65: $spacer * 16.25,
  // 260px
  66: $spacer * 16.5,
  // 264px
  67: $spacer * 16.75,
  // 268px
  68: $spacer * 17,
  // 272px
  69: $spacer * 17.25,
  // 276px
  70: $spacer * 17.5,
  // 280px
  71: $spacer * 17.75,
  // 284px
  72: $spacer * 18,
  // 288px
  80: $spacer * 20,
  // 320px
  81: $spacer * 20.25,
  // 324px
  82: $spacer * 20.5,
  // 328px
  83: $spacer * 20.75,
  // 332px
  84: $spacer * 21,
  // 332px
  85: $spacer * 21.25,
  // 332px
  86: $spacer * 21.5,
  // 332px
  87: $spacer * 21.75,
  // 332px
  88: $spacer * 22,
  // 352px
  96: $spacer * 24,
  // 384px
  104: $spacer * 26,
  // 416px
  112: $spacer * 28,
  // 448px
  116: $spacer * 29,
  // 448px
  120: $spacer * 30,
  // // 480px
  px: 1px,
);

$custom-theme-colors: (
);

@import "@webpixels/css/core/maps";
@import "@webpixels/css/core/mixins";

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");
$utilities-border: map-merge($utilities-colors, $custom-theme-colors);
$utilities-border-color-values: map-loop($utilities-border, rgba-css-var, "$key", "border");

// Import Bootstrap / Webpixel
@import "@webpixels/css/base";
@import "@webpixels/css/forms";
@import "@webpixels/css/components";
@import "@webpixels/css/extras";

@import "@webpixels/css/utilities";
$utilities: map-merge($utilities,
    ("font-weight": (property: font-weight,
        class: font,
        state: hover focus,
        values: $utilities-font-weight-values,
      ),
    ));

@import "bootstrap/scss/utilities/api";