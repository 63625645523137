.form-control-set {
  background-color: transparent;
  box-shadow: none;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.text-main {
  font-size: calc(1rem + 3vw);
}

.font-60 {
  font-size: calc(1.2rem + 2vw);
}

.text-wood-gradient {
  background: linear-gradient(270.99deg, #bf5337 32.28%, #772814 52.91%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.list-check-1{
  li{
    background-image: url('../images/list-type-check.svg');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 16px;
    padding-left: 26px;
  }
}
.max-w-screen-xs {
  max-width: 400px;
}

.min-h-screen-banner {
  min-height: calc(100vh - 68px);
}
.services-svg{
  stroke: $gray-400;
  &:hover{
    stroke: $primary;
  }
}

.slider-card-height-fix {
  .swiper-wrapper {
    .swiper-slide {
      height: auto;
    }
  }
}

.btn-animation {
  span {
    position: relative;
  }

  text-transform: uppercase;
  // color: #fff;
  padding: 16px 25px;
  justify-content: center;
  border-radius: 30px;
  // background: #192020;
  display: inline-flex;
  position: relative;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  transition: box-shadow 0.3s ease,
  background-color 0.3s ease,
  color 0.3s ease,
  background-color 0.3s ease,
  outline 0.3s ease;
  border: 1px solid #192020;
  -webkit-clip-path: inset(0 round 10px);
  clip-path: inset(0 round 10px);

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    top: 0;
    left: 0;
    pointer-events: none;
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
  }

  .button-animation-icon {
    transition: transform 0.3s ease;
    width: 24px;
    height: 24px;

    &::before {
      content: "";
      background-color: #192020;
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-position: center;
      mask-position: center;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: opacity 0.3s ease, background-color 0.3s ease;
    }
  }

  &:hover {
    color: #192020;

    &::before {
      transform-origin: 0% 50%;
      transform: scale3d(1, 1, 1);
    }

    .button-animation-icon {
      transform: rotate(45deg);
    }
  }
}

.object {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.footer-top {
  .footer-social-links {
    display: flex;
    flex-direction: row;
    padding: 0;
    gap: 10px;
    list-style-type: none;
  }

  .footer-menu-title {
    font-weight: 700;
    font-size: 16px;
    color: white;
    margin-bottom: 1rem;
  }

  .footer-menu-column {
    list-style: none;
    padding: 0;

    .footer-menu-link {
      color: white;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      display: block;
      font-size: 14px;

      &:hover {
        color: #6f1f50;
      }
    }
  }
}