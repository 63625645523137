// color
$primary: #bf5337;

// background color
$orange-100: #fffaea;
$orange-200: #FDCCBF;
$orange-900: #772814;
$gray-100: #FEFEFE;

// font-color
$dark: #212529;
$gray-900:#252b2b;
$gray: #495156;
$gray-500: #B1B8BD;
$danger:#FF0000;
$success:#9acd32;